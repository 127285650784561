import { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PageContainer from "../components/pageContainer";
import { InputMode } from "../types/inputMode";
import { useLocation, useParams } from "react-router-dom";
import { getPrompt, updatePrompt } from "../api/prompt";
import { Prompt } from "../types/prompt";
import { getCompanyID } from "../api/config";

export type PromptDetailProps = {
  // mode?: InputMode;
};

const PromptDetail: FC<PromptDetailProps> = (prop) => {
  const [prompt, setPrompt] = useState<Prompt>();
  const [command, setCommand] = useState("");
  const [condition, setCondition] = useState("");
  const [step, setStep] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    getPrompt().then((res) => {
      setPrompt(res);
      setCommand(res.command || "");
      setCondition(res.condition || "");
      setStep(res.step || "");
      console.log(res);
    });

    return () => {};
  }, []);

  return (
    <PageContainer>
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", marginBottom: 1 }}
        >
          プロンプト編集
        </Typography>
        <Typography
          sx={{
            color: "gray",
            paddingBottom: "20px",
          }}
        >
          プロンプト編集
        </Typography>
      </Box>
      <Box
        padding={3}
        display={"grid"}
        rowGap={2}
        border={1}
        borderColor={"#0000001A"}
      >
        <Typography variant="h6">命令文</Typography>
        <TextField
          value={command}
          onChange={(e) => setCommand(e.target.value)}
          fullWidth
          multiline
          minRows={4}
          placeholder="あなたは会社の広報のプロフェッショナルです。&#13;&#10;以下の情報をもとにプレスリリースを日本語で作成してください。"
        ></TextField>
        <Typography variant="h6">作成する文章の条件</Typography>
        <TextField
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          fullWidth
          multiline
          minRows={20}
          placeholder="・発表対象は変更せずにそのまま使用する&#13;&#10;
          ・発表する内容に記載した情報は過不足なく使用する&#13;&#10;
          ・タイトルは釣りにならないよう、平易な表現を用いる&#13;&#10;
          ・タイトルには発表にあたっての重要な要素を盛り込む&#13;&#10;
          ・過去に発表したプレスリリースを今回のテーマに置き換えて作成する&#13;&#10;
          - https://digitalpr.jp/r/80974&#13;&#10;
          - https://digitalpr.jp/r/80620&#13;&#10;
          - https://digitalpr.jp/r/78297&#13;&#10;
          ・発表主はこのリリースを出す企業とする&#13;&#10;
          ・生成する文章は1次ソースとして適切な言い回しにする&#13;&#10;
          ・企業のリリースとして適切な言葉を用いる&#13;&#10;
          ・主語を明確にできない受動態は用いない&#13;&#10;
          ・文章は言い切りにする（希望的観測は使わない）&#13;&#10;
          ・1.本文 2.概要 3.タイトルの順番で情報量を多くする&#13;&#10;
          ※概要に入れた情報は本文でも繰り返し、情報量を確保する&#13;&#10;
          ・リリース内に記載する会社情報は過去のリリースと同じにする（企業名の後ろなど）"
        ></TextField>
        <Typography variant="h6">
          #作成は以下の手順にしたがってください
        </Typography>
        <TextField
          value={step}
          onChange={(e) => setStep(e.target.value)}
          fullWidth
          multiline
          minRows={4}
          placeholder="
          ステップ0：参考URLにアクセスしプレスリリースで良く使われている言い回しや表現、構成・構造を見つけてください&#13;&#10;
ステップ 1：アクセスした過去のプレスリリースを参考にタイトルを作成してください&#13;&#10;
ステップ 2：アクセスした過去のプレスリリースを参考に内容を分割し目次を作成してください&#13;&#10;
ステップ 3：200文字以上の概要を作成してください&#13;&#10;
※概要は本文への導入です。補足情報は含めないでください。&#13;&#10;
ステップ 4：過去のプレスリリースを参考に300文字以上の本文を作成してください（そのとき、目次を見出しとしてください）&#13;&#10;
※本文はプレスリリースの中で一番情報量が多いブロックです。概要に含めなかった情報を後出しする形が最適です。&#13;&#10;
          "
        ></TextField>
        <Button
          variant="contained"
          onClick={() => {
            const promptArg: Prompt = {
              id: prompt?.id ?? 0,
              command: command,
              condition: condition,
              step: step,
            };

            // ここに更新用のメソッドを記載する
            if (promptArg !== undefined) {
              updatePrompt(promptArg).then((res) => {
                if (res.status === 200) {
                  setOpenSnackbar(true);
                }
              });
              return;
            }
          }}
        >
          更新
        </Button>
        {/* <Button variant="text">キャンセル</Button> */}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        message="プロンプトの更新が完了いたしました。"
        onClose={() => setOpenSnackbar(false)}
      />
    </PageContainer>
  );
};
export default PromptDetail;
