import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { ChangeEvent, useState } from "react";
// import { updateAccount } from "../api/accounts";
import AuthContainer from "../components/authContainer";
import { PATHS } from "../paths";
// import { UpdateAccountsParams } from "../types/accounts";
import { useLocation, useNavigate } from "react-router-dom";

const AuthAccount: React.FC = () => {
  // routerの定義
  const navigate = useNavigate();
  const location = useLocation();

  // stateの管理
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState("");
  const [maskedPassword, setMaskedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [maskedPasswordConfirm, setMaskedPasswordConfirm] = useState("");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [loginID, setEmail] = useState("");

  // eventの管理
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };
  // URLからクエリパラメータを抽出
  const searchParams = new URLSearchParams(location.search);
  const myQueryParam = searchParams.get("id"); // 'myParam'を実際のクエリパラメータ名に置き換えてください

  // クエリパラメータの存在に基づいてコンポーネントAを表示するかどうかを決定
  const showPasswordResetComponent = myQueryParam && myQueryParam.length > 0;

  const handlePasswordConfirmChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;

    if (showPasswordConfirm) {
      if (newValue.length > passwordConfirm.length) {
        setPasswordConfirm(
          passwordConfirm + newValue.charAt(newValue.length - 1)
        );
      } else {
        setPasswordConfirm(newValue);
      }
    } else {
      if (newValue.length > maskedPasswordConfirm.length) {
        setPasswordConfirm(
          passwordConfirm + newValue.charAt(newValue.length - 1)
        );
        setMaskedPasswordConfirm(maskedPasswordConfirm + "•");
      } else {
        setPasswordConfirm(passwordConfirm.slice(0, -1));
        setMaskedPasswordConfirm(maskedPasswordConfirm.slice(0, -1));
      }
    }
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (showPassword) {
      if (newValue.length > password.length) {
        setPassword(password + newValue.charAt(newValue.length - 1));
      } else {
        setPassword(newValue);
      }
    } else {
      if (newValue.length > maskedPassword.length) {
        setPassword(password + newValue.charAt(newValue.length - 1));
        setMaskedPassword(maskedPassword + "•");
      } else {
        setPassword(password.slice(0, -1));
        setMaskedPassword(maskedPassword.slice(0, -1));
      }
    }
  };

  const togglePasswordVisibility = () => {
    if (!showPassword) {
      setMaskedPassword("•".repeat(password.length));
    }
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    if (!showPasswordConfirm) {
      setMaskedPasswordConfirm("•".repeat(passwordConfirm.length));
    }
    setShowPasswordConfirm(!showPassword);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // var isError = false;

    // if (password !== password) {
    //   isError = true;
    //   setIsError(isError);
    // }

    // if (isError) return;

    // const myAccount: UpdateAccountsParams = {
    //   login_id: "12345678",
    //   password: "demopassword",
    //   user_name: "デモ山太郎",
    //   user_info: "string",
    //   role_id: 1,
    //   duties: [1],
    // };

    // updateAccount("12345678", myAccount).then((res) => {
    //   if (res.status === 200) {
    //     navigate(PATHS.AUTH_LOGIN);
    //   }
    // });

    navigate(PATHS.DRAFT);
  };

  return (
    <AuthContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "85vh",
        }}
      >
        <Card
          elevation={3}
          sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <Box
            width={"540px"}
            paddingInline={2}
            paddingBlock={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            {!showPasswordResetComponent ? (
              <>
                <Box mb={2}>
                  <Typography variant="h5">アカウント作成</Typography>
                  <Typography variant="body2" sx={{ color: "grey" }}>
                    アカウント情報を入力してください。
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                      fullWidth
                      value={loginID}
                      type="text"
                      id="email-input"
                      onChange={handleEmailChange}
                      label="ログインID(メールアドレス)"
                      autoComplete="off"
                    ></TextField>
                    <TextField
                      type="text"
                      fullWidth
                      onChange={handlePasswordChange}
                      label="新しいパスワード"
                      value={showPassword ? password : maskedPassword}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <Button
                      sx={{
                        height: "42px",
                      }}
                      type="submit"
                      className="login-button"
                      variant="contained"
                      fullWidth
                    >
                      <Typography>次へ</Typography>
                    </Button>
                  </Box>
                </form>
              </>
            ) : (
              <>
                <Box mb={2}>
                  <Typography variant="h5">パスワードの再設定</Typography>
                  <Typography variant="body2" sx={{ color: "grey" }}>
                    新しいパスワードと確認用パスワードを入力してください。
                  </Typography>
                </Box>
                {isError ? (
                  <Alert severity="error">
                    過去に使用したパスワードはご利用になれません。
                  </Alert>
                ) : null}
                <form onSubmit={handleSubmit}>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                      type="text"
                      fullWidth
                      onChange={handlePasswordChange}
                      label="新しいパスワード"
                      value={showPassword ? password : maskedPassword}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <TextField
                      type="text"
                      fullWidth
                      onChange={handlePasswordConfirmChange}
                      label="確認用パスワード"
                      value={
                        showPasswordConfirm
                          ? passwordConfirm
                          : maskedPasswordConfirm
                      }
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordConfirmVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <Button
                      sx={{
                        height: "42px",
                      }}
                      type="submit"
                      className="login-button"
                      variant="contained"
                      fullWidth
                    >
                      <Typography>確定</Typography>
                    </Button>
                  </Box>
                </form>
              </>
            )}
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Button variant="text" onClick={() => navigate(PATHS.AUTH_LOGIN)}>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline" }}
                >
                  すでにアカウントをお持ちの方はこちら
                </Typography>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthAccount;
