import { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PageContainer from "../components/pageContainer";
import SubMenuItem from "../components/menuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompany, updateCompany } from "../api/company";
import { InputMode } from "../types/inputMode";
import { Company, CompanyUpdate } from "../types/company";
import { getCompanyID } from "../api/config";
import { useRecoilValue } from "recoil";
import { myRoleState } from "../recoil/sideBarState";

const BasicInfo: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode") as InputMode;
  // 状態変数の追加
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [summary, setSummary] = useState("");
  const isEditMode = mode === InputMode.UPDATE;
  const buttonVariant = isEditMode ? "contained" : "outlined";
  const role = useRecoilValue(myRoleState);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbarの表示状態

  useEffect(() => {
    getCompany().then((res: Company) => {
      setCompanyName(res.data.company_name);
      setAddress(res.data.address);
      setPhone(res.data.tel);
      setSummary(res.data.summary);
      setContact(res.data.staff);
      setEmail(res.data.email);
    });
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography variant="h6">基本情報</Typography>

        <Button
          variant={buttonVariant}
          onClick={() => {
            const company_id = getCompanyID();
            if (company_id === undefined) return;

            const updatedInfo: CompanyUpdate = {
              company_id: company_id!,
              company_name: companyName,
              address: address,
              tel: phone,
              staff: contact,
              email: email,
              summary: summary,
              role: role,
              create_datetime: Date.now().toString(),
              update_datetime: Date.now().toString(),
            };
            updateCompany(updatedInfo).then((res) => {
              if (res.status === 200) {
                setOpenSnackbar(true);
              }
            });
          }}
        >
          編集
        </Button>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>企業名/屋号</TableCell>
            <TableCell>
              <TextField
                fullWidth
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>住所</TableCell>
            <TableCell>
              <TextField
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>電話番号</TableCell>
            <TableCell>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>担当者</TableCell>
            <TableCell>
              <TextField
                fullWidth
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>メールアドレス</TableCell>
            <TableCell>
              <TextField
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>更新日</TableCell>
            <TableCell>
              <TextField fullWidth></TextField>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="情報が更新されました"
      />
    </>
  );
};

const BasicCompany: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode") as InputMode;
  // 状態変数の追加
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [summary, setSummary] = useState("");
  const isEditMode = mode === InputMode.UPDATE;
  const buttonVariant = isEditMode ? "contained" : "outlined";
  const role = useRecoilValue(myRoleState);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbarの表示状態

  useEffect(() => {
    getCompany().then((res: Company) => {
      setCompanyName(res.data.company_name);
      setAddress(res.data.address);
      setPhone(res.data.tel);
      setSummary(res.data.summary);
      setContact(res.data.staff);
      setEmail(res.data.email);
    });
  }, []);

  useEffect(() => {
    getCompany().then((res: Company) => {
      setSummary(res.data.summary);
    });
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography variant="h6">会社概要</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            const company_id = getCompanyID();
            if (company_id === undefined) return;

            const updatedInfo: CompanyUpdate = {
              company_id: company_id!,
              company_name: companyName,
              address: address,
              tel: phone,
              staff: contact,
              email: email,
              summary: summary,
              role: role,
              create_datetime: Date.now().toString(),
              update_datetime: Date.now().toString(),
            };
            updateCompany(updatedInfo).then((res) => {
              setOpenSnackbar(true);
            });
          }}
        >
          編集
        </Button>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>概要テキスト</TableCell>
            <TableCell>
              <TextField
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                fullWidth
                multiline
                minRows={3}
              ></TextField>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="情報が更新されました"
      />
    </>
  );
};

export type SettingDefaultProps = {};

const SettingDefault: FC<SettingDefaultProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialContent = queryParams.get("tab") || "basicInfo";

  const [selectedContent, setSelectedContent] = useState(initialContent);

  // タブを切り替えるための関数
  const handleTabChange = (tabId: string) => {
    setSelectedContent(tabId);
    navigate(`${location.pathname}?tab=${tabId}`);
  };

  // リストアイテムのデータを定義
  const listItems = [
    { id: "basicInfo", text: "基本情報", component: <BasicInfo /> },
    { id: "basicCompany", text: "会社概要", component: <BasicCompany /> },
  ];

  useEffect(() => {
    setSelectedContent(initialContent);
  }, [initialContent]);

  return (
    <PageContainer>
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", marginBottom: 1 }}
        >
          設定
        </Typography>
        <Typography
          sx={{
            color: "gray",
            paddingBottom: "20px",
          }}
        >
          設定 / 基本情報
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        // alignItems={"center"}
        border={1}
        borderColor={"#0000001A"}
      >
        <List
          sx={{
            borderRight: 1,
            borderColor: "#0000001A",
            width: "180px",
            padding: 1,
          }}
        >
          {listItems.map((item) => (
            <SubMenuItem
              key={item.id}
              text={item.text}
              selected={selectedContent === item.id}
              onClick={() => handleTabChange(item.id)}
            />
          ))}
        </List>
        <Box
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          rowGap={2}
          padding={2}
        >
          {listItems.find((item) => item.id === selectedContent)?.component}
        </Box>
      </Box>
    </PageContainer>
  );
};
export default SettingDefault;
