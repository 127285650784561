export const getAxiosConfig = () => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const setAccessToken = (token: string) => {
  localStorage.setItem("accessToken", token);
};

export const setCompanyID = (companyID: string) => {
  localStorage.setItem("companyID", companyID);
};

export const getCompanyID = () => {
  return localStorage.getItem("companyID");
};

export const getTokenDatetime = () => {
  const tokenDatetime = localStorage.getItem("tokenDatetime");
  if (tokenDatetime) {
    return new Date(tokenDatetime);
  }
  return null;
};

export const setRefreshToken = (token: string) => {
  console.log(token);
  localStorage.setItem("refreshToken", token);
};

export const setTokenDatetime = () => {
  const now = new Date();
  localStorage.setItem("tokenDatetime", now.toString());
};

export const clearToken = () => {
  localStorage.setItem("accessToken", "");
  localStorage.setItem("refreshToken", "");
  localStorage.setItem("tokenDatetime", "");

  console.log("token cleared");
};

