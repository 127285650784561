import { MenuData } from "../types/menu";
import { PATHS } from "../paths";

export const getMenu = async (role: string): Promise<MenuData> => {
  if (role === "admin") {
    return {
      data: [
        {
          group_id: "1",
          group_name: "コール",
          menu: [
            {
              number: 1,
              title: "ドラフト管理",
              url: PATHS.DRAFT,
              icon: "<ReceiptOutlinedIcon />",
              popup: false,
            },
            {
              number: 2,
              title: "プロンプト編集",
              url: PATHS.PRONPT,
              icon: "<ConfirmationNumberOutlined />",
              popup: false,
            },
            {
              number: 3,
              title: "アカウント管理",
              url: PATHS.ACCOUNT,
              icon: "<BusinessOutlined />",
              popup: false,
            },
            {
              number: 4,
              title: "設定",
              url: PATHS.SETTING_USER,
              icon: "<SettingsOutlinedIcon />",
              popup: false,
            },
            {
              number: 5,
              title: "パスワード変更",
              url: PATHS.AUTH_PASSWORD_RESET,
              icon: "<ManageAccountOutlinedIcon />",
              popup: false,
            },
            {
              number: 6,
              title: "ログアウト",
              url: PATHS.AUTH_LOGIN,
              icon: "<LogoutOutlined />",
              popup: false,
            },
          ],
        },
      ],
    };
  } else {
    return {
      data: [
        {
          group_id: "1",
          group_name: "コール",
          menu: [
            {
              number: 1,
              title: "ドラフト管理",
              url: PATHS.DRAFT,
              icon: "<ReceiptOutlinedIcon />",
              popup: false,
            },
            {
              number: 4,
              title: "設定",
              url: PATHS.SETTING_USER,
              icon: "<SettingsOutlinedIcon />",
              popup: false,
            },
            {
              number: 5,
              title: "パスワード変更",
              url: PATHS.AUTH_PASSWORD_RESET,
              icon: "<ManageAccountOutlinedIcon />",
              popup: false,
            },
            {
              number: 6,
              title: "ログアウト",
              url: PATHS.AUTH_LOGIN,
              icon: "<LogoutOutlined />",
              popup: false,
            },
          ],
        },
      ],
    };
  }
};

// {
//   group_id: "2",
//   group_name: "レポート",
//   menu: [
//     {
//       number: 1,
//       title: "着信数レポート",
//       url: PATHS.REPORT_CALL,
//       icon: "<BarChartOutlined />",
//       popup: false,
//     },
//     {
//       number: 2,
//       title: "KPIレポート",
//       url: PATHS.REPORT_KPI,
//       icon: "<ReceiptLongOutlinedIcon />",
//       popup: false,
//     },
//   ],
// },
// {
//   group_id: "3",
//   group_name: "音声管理",
//   menu: [
//     {
//       number: 1,
//       title: "音声ガイダンス",
//       url: PATHS.VOICE_GUIDANCE,
//       icon: "<GraphicEqOutlinedIcon />",
//       popup: false,
//     },
//     {
//       number: 2,
//       title: "音声認識辞書",
//       url: PATHS.VOICE_DICTIONARY,
//       icon: "<ImportContactsOutlinedIcon />",
//       popup: false,
//     },
//   ],
// },
// {
//   group_id: "4",
//   group_name: "設定",
//   menu: [
//     {
//       number: 1,
//       title: "特殊日管理",
//       url: PATHS.SETTING_DAY,
//       icon: "<CalendarMonthOutlinedIcon />",
//       popup: false,
//     },
//     {
//       number: 2,
//       title: "郵便番号管理",
//       url: PATHS.SETTING_ZIP,
//       icon: "<HomeOutlinedIcon />",
//       popup: false,
//     },
//     {
//       number: 3,
//       title: "詳細設定",
//       url: PATHS.SETTING_DETAIL,
//       icon: "<SettingsOutlinedIcon />",
//       popup: false,
//     },
//   ],
// },

// {
//   group_id: "5",
//   group_name: "管理者",
//   menu: [
//     {
//       number: 1,
//       title: "操作ログ",
//       url: PATHS.ADMIN_LOG,
//       icon: "<HistoryEduOutlinedIcon />",
//       popup: false,
//     },
//     {
//       number: 2,
//       title: "アカウント管理",
//       url: PATHS.ADMIN_ACCOUNT,
//       icon: "<ManageAccountsOutlinedIcon />",
//       popup: false,
//     },
//   ],
// },
// {
//   group_id: "6",
//   group_name: "デベロッパ",
//   menu: [
//     {
//       number: 1,
//       title: "フローマスタ",
//       url: PATHS.DEVELOPER_FLOW,
//       icon: "<AccountTreeOutlinedIcon />",
//       popup: false,
//     },
//     {
//       number: 2,
//       title: "WatsonNLUモデル",
//       url: PATHS.DEVELOPER_WATSON,
//       icon: "<PsychologyOutlinedIcon />",
//       popup: false,
//     },
//     {
//       number: 3,
//       title: "TensorFlowモデル",
//       url: PATHS.DEVELOPER_TENSOR,
//       icon: "<RouteOutlinedIcon />",
//       popup: false,
//     },
//   ],
// },

// export const getInformation = async (): Promise<Information> => {
//   // const result = await axios.get(
//   //   `${process.env.REACT_APP_API_URL}/menus/information?screen_id=LG001&target_id=LG001_01`,
//   //   getAxiosConfig()
//   // );
//   // return result.data;

//   const info = {} as Information;

//   return info;
// };

// export const updateInformation = async (
//   comment: string,
//   level: string
// ): Promise<Information> => {
//   const result = await axios.post(
//     `${process.env.REACT_APP_API_URL}/menus/information/update`,
//     {
//       screen_id: "MA001",
//       target_id: "MA001_01",
//       parameters: {
//         message: comment,
//         level: level,
//       },
//     },
//     getAxiosConfig()
//   );
//   return result.data;
// };
