import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import AuthLogin from "./pages/authLogin";
import AuthPasswordForGet from "./pages/authPasswordForget";
import DraftDetail from "./pages/draftDetail";
import SettingDefault from "./pages/settingDefault";
import DraftList from "./pages/draftList";
import { PATHS } from "./paths";
import AuthAccount from "./pages/authAccount";
import AccountList from "./pages/accountList";
import AccountDetail from "./pages/accountDetail";
import PromptDetail from "./pages/promptDetail";
import AuthPasswordReset from "./pages/authPasswordReset";
import { useEffect } from "react";
import { refreshToken } from "./api/auth";
import { getTokenDatetime } from "./api/config";

function AppRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === PATHS.AUTH_LOGIN ||
      location.pathname === PATHS.AUTH_ACCOUNT ||
      location.pathname === PATHS.AUTH_PASSWORD_FORGET ||
      location.pathname === PATHS.AUTH_PASSWORD_RESET
    ) {
      return;
    }

    const intervalId = setInterval(() => {
      refreshToken()
        .then((res) => {
          if (res.status !== 200) {
            navigate(PATHS.AUTH_LOGIN);
          }
        })
        .catch(() => {
          navigate(PATHS.AUTH_LOGIN);
        });
    }, 1000 * 60 * 60); // 1時間毎

    var tokenDateTime = getTokenDatetime();
    if (!tokenDateTime) {
      navigate(PATHS.AUTH_LOGIN);
      return;
    }

    var now = new Date();
    // 最終取得時間から1時間未満 → 何もしない
    // 最終取得時間から1時間以上3時間未満 → トークンを更新する
    // 最終取得時間から3時間以上 → ログイン画面に遷移する

    if (now.getTime() - tokenDateTime.getTime() < 1000 * 60 * 60) {
      return;
    } else if (
      now.getTime() - tokenDateTime.getTime() >= 1000 * 60 * 60 * 1 &&
      now.getTime() - tokenDateTime.getTime() < 1000 * 60 * 60 * 3
    ) {
      refreshToken().then((res) => {
        if (res.status !== 200) {
          navigate(PATHS.AUTH_LOGIN);
          return;
        }
      });
    } else {
      navigate(PATHS.AUTH_LOGIN);
      return;
    }

    return () => {
      clearInterval(intervalId); // クリーンアップ
    };
  }, [location.pathname, navigate]);

  return (
    <Routes>
      {/* 認証 */}
      <Route path={PATHS.AUTH_LOGIN} element={<AuthLogin />} />
      <Route path={PATHS.AUTH_PASSWORD_RESET} element={<AuthPasswordReset />} />
      <Route
        path={PATHS.AUTH_PASSWORD_FORGET}
        element={<AuthPasswordForGet />}
      />
      <Route path={PATHS.AUTH_ACCOUNT} element={<AuthAccount />} />
      {/* ドラフト */}
      <Route path={PATHS.DRAFT} element={<DraftList />} />
      <Route path={`${PATHS.DRAFT}/:id`} element={<DraftDetail />} />
      {/* 管理者 */}
      <Route path={PATHS.PRONPT} element={<PromptDetail />} />
      <Route path={PATHS.ACCOUNT} element={<AccountList />} />
      <Route path={`${PATHS.ACCOUNT}/:id`} element={<AccountDetail />} />
      {/* <Route path={`${PATHS.ACCOUNT_DETAIL}/:mode`} element={<AccountDetail />} /> */}
      <Route path={PATHS.SETTING_USER} element={<SettingDefault />} />
    </Routes>
  );
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#096BDE",
        contrastText: "#fff",
      },
      background: {
        default: "#FAFAFA",
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        '"Noto Sans JP"',
        '"Helvetica"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          dateFormats={{ monthAndYear: "YYYY年MM月" }}
          adapterLocale="ja"
        >
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </LocalizationProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
