import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { ChangeEvent, useState } from "react";
import AuthContainer from "../components/authContainer";
import { PATHS } from "../paths";
import { useNavigate } from "react-router-dom";
import { login } from "../api/auth";
import { myRoleState } from "../recoil/sideBarState";
import { useSetRecoilState } from "recoil";

const AuthLogin: React.FC = () => {
  // routerの定義
  const navigate = useNavigate();

  // stateの管理
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [maskedPassword, setMaskedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const setMyRole = useSetRecoilState(myRoleState);

  // const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const newValue = event.target.value;

  //   if (showPassword) {
  //     // showPasswordがtrueの場合、最後尾の文字のみを更新
  //     if (newValue.length > password.length) {
  //       setPassword(password + newValue.charAt(newValue.length - 1));
  //     } else {
  //       // バックスペースなどで文字が削除された場合
  //       setPassword(newValue);
  //     }
  //   } else {
  //     // showPasswordがfalseの場合、パスワードを伏字で管理
  //     if (newValue.length > maskedPassword.length) {
  //       setPassword(password + newValue.charAt(newValue.length - 1));
  //       setMaskedPassword(maskedPassword + "•");
  //     } else {
  //       setPassword(password.slice(0, -1));
  //       setMaskedPassword(maskedPassword.slice(0, -1));
  //     }
  //   }
  // };

  // const togglePasswordVisibility = () => {
  //   if (!showPassword) {
  //     setMaskedPassword("•".repeat(password.length));
  //   }
  //   setShowPassword(!showPassword);
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // eventの管理
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const navigateForgotPassword = () => {
    navigate(PATHS.AUTH_PASSWORD_RESET);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // // もしデバッグモードなら、デバッグ用のログイン情報を使用する
    // if (process.env.REACT_APP_DEBUG_MODE === "true") {
    //   email = "alt.du-39px50u@yopmail.com";
    //   password = "Manerek-1234";
    // }

    login(email, password)
      .then((res) => {
        console.log(res);

        if (res.data["1"]) {
          setIsError(true);
          return;
        }

        if (res.status === 200) {
          setMyRole(res.data.role);
          navigate(PATHS.DRAFT);
        } else if (res.status === 500) {
          setIsError(true);
        } else if (res.status === 999) {
          // navigate(PATHS.CALL_LIST);
          return;
        } else {
          setIsError(true);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AuthContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "85vh",
        }}
      >
        <Card
          elevation={3}
          sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <Box
            width={"540px"}
            paddingInline={2}
            paddingBlock={4}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="h5" paddingBlock={2}>
              ログイン
            </Typography>
            {isError ? (
              <Alert severity="error">
                ログインIDまたはパスワードが間違っています。
              </Alert>
            ) : null}
            <form onSubmit={handleSubmit}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  fullWidth
                  value={email}
                  type="text"
                  onChange={handleEmailChange}
                  label="ログインID(メールアドレス)"
                  // autoComplete="off"
                ></TextField>
                <TextField
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  // onChange={handlePasswordChange}
                  onChange={(e) => setPassword(e.target.value)}
                  label="パスワード"
                  value={password}
                  // value={showPassword ? password : maskedPassword}
                  // autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <Button
                  sx={{
                    height: "42px",
                  }}
                  type="submit"
                  className="login-button"
                  variant="contained"
                  fullWidth
                >
                  <Typography>ログイン</Typography>
                </Button>
              </Box>
            </form>
            {/* <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <Button
                variant="text"
                onClick={() => navigate(PATHS.AUTH_PASSWORD_FORGET)}
              >
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline" }}
                >
                  パスワードをお忘れですか？
                </Typography>
              </Button>
              <Button
                variant="text"
                onClick={() => navigate(PATHS.AUTH_ACCOUNT)}
              >
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline" }}
                >
                  まだアカウントをお持ちでない方はこちら
                </Typography>
              </Button>
            </Box> */}
          </Box>
        </Card>
      </Box>
    </AuthContainer>
  );
};

export default AuthLogin;
