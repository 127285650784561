import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

import ContactPhoneIcon from "@mui/icons-material/ContactPhoneOutlined";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
// import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import logoIcon from "../assets/logo.png";
import logoSmallIcon from "../assets/logo_small.png";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Link, useLocation } from "react-router-dom";
import {
  BarChartOutlined,
  BusinessOutlined,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import openCenteredPopup from "../utils/urlUtil";
import {
  isMenuOpenState,
  myRoleState,
  sidebarScrollPositionState,
} from "../recoil/sideBarState";
import { MenuData } from "../types/menu";
import { getMenu } from "../api/menus";

export interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState);
  const [scrollPosition, setScrollPosition] = useRecoilState(
    sidebarScrollPositionState
  );
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [menus, setMenus] = useState<MenuData>({ data: [] }); // Stateの追加
  const menuWidth = isMenuOpen ? "290px" : "90px";
  const role = useRecoilValue(myRoleState);

  const handleScroll = () => {
    if (sidebarRef.current) {
      setScrollPosition(sidebarRef.current.scrollTop);
    }
  };

  const fetchMenu = async () => {
    try {
      const data = await getMenu(role);
      setMenus(data);
    } catch (error) {
      console.error("Failed to fetch menu:", error);
    }
  };

  useEffect(() => {
    if (menus.data.length === 0) {
      fetchMenu();
    }

    if (sidebarRef.current) {
      sidebarRef.current.scrollTop = scrollPosition;
    }
  }, [menus.data.length, scrollPosition]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openLinkInNewWindow = (url: string | URL | undefined) => {
    openCenteredPopup(url, 1200, 600);
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "<ContactPhoneIcon />":
        return <ContactPhoneIcon />;
      case "<DvrOutlinedIcon />":
        return <DvrOutlinedIcon />;
      case "<BarChartOutlined />":
        return <BarChartOutlined />;
      case "<ReceiptLongOutlinedIcon />":
        return <ReceiptLongOutlinedIcon />;
      case "<GraphicEqOutlinedIcon />":
        return <GraphicEqOutlinedIcon />;
      case "<ImportContactsOutlinedIcon />":
        return <ImportContactsOutlinedIcon />;
      case "<CalendarMonthOutlinedIcon />":
        return <CalendarMonthOutlinedIcon />;
      case "<HomeOutlinedIcon />":
        return <HomeOutlinedIcon />;
      case "<SettingsOutlinedIcon />":
        return <SettingsOutlinedIcon />;
      case "<HistoryEduOutlinedIcon />":
        return <HomeOutlinedIcon />;
      case "<ManageAccountsOutlinedIcon />":
        return <SettingsOutlinedIcon />;
      case "<AccountTreeOutlinedIcon />":
        return <HistoryEduOutlinedIcon />;
      case "<PsychologyOutlinedIcon />":
        return <ManageAccountsOutlinedIcon />;
      case "<RouteOutlinedIcon />":
        return <AccountTreeOutlinedIcon />;
      case "<LaunchOutlinedIcon />":
        return <PsychologyOutlinedIcon />;
      case "<ReceiptOutlinedIcon />":
        return <ReceiptOutlinedIcon />;
      case "<ConfirmationNumberOutlined />":
        return <ConfirmationNumberOutlined />;
      case "<BusinessOutlined />":
        return <BusinessOutlined />;
      case "<LogoutOutlined />":
        return <LogoutOutlined />;
      case "<ManageAccountOutlinedIcon />":
        return <ManageAccountsOutlinedIcon />;

      default:
        return <ContactPhoneIcon />;
    }
  };

  const drawer = (
    <Box
      key={"sidebar"}
      ref={sidebarRef}
      onScroll={handleScroll}
      width="100%"
      padding="0 8px 0 8px"
      sx={{
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        textAlign={{ sx: "left" }}
        flexGrow={1}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"space-between"}
        display={"flex"}
        height={"64px"}
        sx={{
          textAlign: "left",
        }}
      >
        <style>
          {`
            img.logo {
                width: 100%;
                max-width: 165px;
                padding: 15px;
             }

            img.logoSmall {
                width: 100%;
                max-width: 32px;
                padding: 15px 0;
            }
          `}
        </style>
        {isMenuOpen ? (
          <>
            <img className="logo" src={logoIcon} alt="" />
            <IconButton onClick={toggleMenu}>
              <KeyboardDoubleArrowLeftIcon
              // sx={{
              //   color: "white",
              // }}
              />
            </IconButton>
          </>
        ) : (
          <>
            <img className="logoSmall" src={logoSmallIcon} alt="" />
            <IconButton onClick={toggleMenu}>
              <KeyboardDoubleArrowRightIcon
              // sx={{
              //   color: "white",
              // }}
              />
            </IconButton>
          </>
        )}
      </Box>
      {menus.data.map((group, groupIndex) => {
        return (
          <Box key={groupIndex}>
            <List>
              {/* <ListItem
                key={group.group_id}
                disablePadding={!isMenuOpen}
                sx={{
                  padding: isMenuOpen ? null : "8px 0",
                  textAlign: isMenuOpen ? null : "center",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontSize: isMenuOpen ? "14px" : "12px",
                      // color: "white",
                    },
                  }}
                  primary={group.group_name}
                />
              </ListItem> */}
              {group.menu.map((menu, menuIndex) => {
                const isSelected = location.pathname === menu.url;

                return (
                  <ListItem key={menuIndex} disablePadding>
                    <Tooltip
                      title={isMenuOpen ? "" : menu.title}
                      placement="right"
                    >
                      <ListItemButton
                        component={Link}
                        to={menu.url}
                        onClick={(event) => {
                          if (menu.popup) {
                            event.preventDefault();
                            openLinkInNewWindow(menu.url);
                          }
                        }}
                        // target={menu.popup ? "_blank" : undefined}
                        sx={{
                          borderRadius: "4px",
                          width: "100%",
                          justifyContent: isMenuOpen ? null : "center",
                          padding: isMenuOpen ? null : "8px 0",
                          backgroundColor: isSelected
                            ? // ? "primary.light"
                              "#5B98F414"
                            : "inherit",
                          "&:hover": {
                            // backgroundColor: "primary.light",
                            backgroundColor: "#5B98F414",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: isSelected ? "primary.main" : "inherit",
                            minWidth: isMenuOpen ? null : 0,
                          }}
                        >
                          {getIcon(menu.icon)}
                        </ListItemIcon>
                        {/* {isMenuOpen && (
                          <ListItemText
                            primary={menu.title}
                            sx={{
                              opacity: isMenuOpen ? 1 : 0,
                              transition: "opacity 0.3s ease",
                              transitionDelay: isMenuOpen ? "0.3s" : "0s",
                            }}
                          />
                        )} */}

                        <ListItemText
                          primary={isMenuOpen ? menu.title : ""}
                          sx={{
                            // maxWidth: isMenuOpen ? "100%" : "0%",

                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            flexGrow: isMenuOpen ? 1 : 0,
                            opacity: isMenuOpen ? 1 : 0,
                            visibility: isMenuOpen ? "visible" : "hidden",
                            transition: isMenuOpen
                              ? "max-width 0.3s ease 0.3s, opacity 0.2s ease 0.3s, visibility 0s ease 0.3s"
                              : "max-width 0.3s ease, opacity 0.2s ease, visibility 0s ease 0.3s",
                          }}
                        />
                        {isMenuOpen && menu.popup && <LaunchOutlinedIcon />}
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                );
              })}
            </List>
            {/* <Divider sx={{ backgroundColor: "primary.light" }} /> */}
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box
      component="nav"
      display="flex"
      flexShrink={0}
      flexGrow={0}
      width={menuWidth}
      borderRight={1}
      borderColor={"#E5E5E5"}
      sx={{
        transition: "width 0.3s ease", // 幅の変更にアニメーションを追加
        // bgcolor: "primary.main",
        // color: "white",
      }}
    >
      {drawer}
    </Box>
  );
};
export default Sidebar;
