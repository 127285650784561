import AppBar, { AppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton/IconButton";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import React from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
// import { AuthApi } from "../../api/generator/api";
import Alert, { AlertColor } from "@mui/material/Alert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import openCenteredPopup from "../utils/urlUtil";
import { logout } from "../api/auth";
// import { getInformation, updateInformation } from "../api/menus";
import { Information } from "../types/information";
import { DateTimePicker } from "@mui/x-date-pickers";
// import { Account } from "../types/accounts";
// import { getAccount } from "../api/accounts";
import { PATHS } from "../paths";
import { useNavigate } from "react-router-dom";

export type HeaderProps = {} & AppBarProps;

const Header: FC<HeaderProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAccount, setOpenAccount] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [information, setInformation] = useState<Information>();
  // const [account, setAccount] = useState<Account>();
  const [newInformation, setNewInformation] = useState<Information>();
  const [infoType, setinfoType] = useState<AlertColor>("success");
  const [newInfoType, setNewInfoType] = useState<AlertColor>("success");
  // const [comment, setComment] = useState<Information>();
  const navigate = useNavigate();

  useEffect(() => {
    // getInformation().then((information: Information) => {
    //   setInformation(information);
    //   setNewInformation(information);
    // });

    switch (information?.level) {
      case 1:
        setinfoType("success");
        break;
      case 2:
        setinfoType("info");
        break;
      case 3:
        setinfoType("warning");
        break;
      case 4:
        setinfoType("error");
        break;
      default:
        break;
    }

    // setinfoType

    // getAccount(12345678).then((data: Account) => {
    //   setAccount(data);
    // });
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenAccount(true);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
    setOpenAccount(false);
  };

  const handleOpenNoticeMenu = (event: React.MouseEvent<HTMLElement>) => {
    // openCenteredPopup(PATHS.CALL_POPUP, 1400, 796);
  };

  const naviPassword = () => {
    navigate(PATHS.AUTH_PASSWORD_RESET);
  };

  const handleLogout = () => {
    logout().then((res) => {
      if (res.status === 200) {
        navigate(PATHS.AUTH_LOGIN);
      }
    });
  };

  const handleOpenDialogMessage = () => {
    setOpenMessage(true);
  };

  const handleCloseDialogMessage = () => {
    setOpenMessage(false);
  };

  const handleConfirmDialogMessage = () => {
    // updateInformation(information?.message ?? "", "1");
    setOpenMessage(false);
  };

  const handleClearMessage = () => {
    setOpenMessage(false);
  };

  const handleChangeInfoType = (event: SelectChangeEvent) => {
    const value = event.target.value as AlertColor;
    setNewInfoType(value);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <AppBar
      color="transparent"
      position="sticky"
      elevation={0}
      sx={{
        width: "100%",
        minWidth: "960px",
        // height: "54px",
        backgroundColor: "white",
        // display: "flex",
        display: "none",
        flexDirection: "row",
        justifyContent: "flex-end",
        // flexDirection: "flex-end",
        alignItems: "center",
        paddingInline: 2,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {/* <Alert
        severity={infoType}
        action={
          <>
            <IconButton onClick={handleOpenDialogMessage}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleClearMessage}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        }
        sx={{
          height: "auto",
          width: "70%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Typography
          variant="body2"
          fontWeight="bolder"
          padding={0}
          textOverflow={"ellipsis"}
          overflow="hidden" // 要素外のテキストを隠す
          whiteSpace="nowrap" // テキストを改行しない
          sx={{ maxWidth: "100%" }} // 最大幅を100%に設定（必要に応じて調整）
        >
          {information?.message}
        </Typography>
      </Alert> */}
      {/* <Dialog open={openMessage} onClose={handleCloseDialogMessage} fullWidth>
        <DialogTitle>{"共有メッセージ"}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box rowGap={2} display={"flex"} flexDirection={"column"}>
            <Alert
              severity={newInfoType}
              action={
                <>
                  <IconButton>
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </>
              }
              sx={{
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body2"
                fontWeight="bolder"
                padding={0}
                textOverflow={"ellipsis"}
                overflow="hidden"
                whiteSpace="nowrap"
                sx={{ maxWidth: "100%" }}
              >
                {newInformation?.message}
              </Typography>
            </Alert>
            <FormControl fullWidth>
              <Select value={newInfoType} onChange={handleChangeInfoType}>
                <MenuItem value={"error"}>警告</MenuItem>
                <MenuItem value={"warning"}>注意</MenuItem>
                <MenuItem value={"info"}>情報</MenuItem>
                <MenuItem value={"success"}>成功</MenuItem>
              </Select>
            </FormControl>
            <TextField
              multiline
              minRows={3}
              placeholder="メッセージ内容"
              value={newInformation?.message}
              onChange={(e) => {
                if (!newInformation) return;
                const updated = {
                  ...newInformation,
                  message: e.target.value,
                } as Information;
                setNewInformation(updated);
              }}
            ></TextField>
            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={handleChangeChecked} />
              }
              label={<Typography variant="body2">期間を指定する</Typography>}
            />
            <Box display={"flex"} alignItems={"center"} columnGap={2}>
              <DateTimePicker
                ampm={false}
                sx={{ flexGrow: "1" }}
                format="YYYY/MM/DD HH:mm"
              />
              <Typography>〜</Typography>
              <DateTimePicker
                sx={{ flexGrow: "1" }}
                format="YYYY/MM/DD HH:mm"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogMessage}
            sx={{
              color: "grey",
            }}
          >
            閉じる
          </Button>
          <Button
            onClick={handleConfirmDialogMessage}
            variant="contained"
            autoFocus
          >
            適用
          </Button>
        </DialogActions>
      </Dialog> */}
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {/* <Tooltip title="通知" sx={{}}>
            <IconButton onClick={handleOpenNoticeMenu} sx={{ mr: 2 }}>
              <NotificationsNoneOutlinedIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="アカウント設定">
            <Box
              onClick={handleOpenUserMenu}
              sx={{
                p: 0,
                mr: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                whiteSpace: "nowrap",
              }}
            >
              <Typography whiteSpace={"nowrap"} sx={{ color: "primary.dark" }}>
                {/* {account?.name} */}
                三船 直人
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  color: "text.secondary",
                  fontSize: "0.8rem",
                }}
              >
                {/* 前回ログイン：{convertDateTimeFormat(account?.last_logined)} */}
                {/* 前回ログイン：{convertDateTimeFormat("2021-10-01 12:00:00")} */}
                前回ログイン：2021-10-01 12:00:00
              </Typography>
            </Box>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={openAccount}
            onClose={handleCloseUserMenu}
            sx={{ mt: 5 }}
          >
            <MenuItem onClick={naviPassword}>パスワード変更</MenuItem>
          </Menu>
          <Tooltip title="ログアウト">
            <IconButton onClick={handleLogout}>
              <LogoutOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
