import axios, { AxiosResponse } from "axios";
import { getAxiosConfig } from "./config";
import { Prompt } from "../types/prompt";

// getList
export const getPrompt = async (): Promise<Prompt> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/prompt/get`,
    getAxiosConfig()
  );

  return response.data;
};

// getDetail
export const updatePrompt = async (data: Prompt): Promise<AxiosResponse> => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/prompt/update`,
    {
      id: data.id,
      command: data.command,
      condition: data.condition,
      step: data.step,
    },
    getAxiosConfig()
  );
};
