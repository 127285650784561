import axios, { AxiosResponse } from "axios";
import { getAxiosConfig } from "./config";

import { Account } from "../types/accounts";

export const createAccount = async (data: Account): Promise<AxiosResponse> => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/company/create`,
    {
      username: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      company_name: data.company_name,
    },
    getAxiosConfig()
  );
};
