import React from "react";
import { FC, useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ContentPasteOutlined, HelpOutlineOutlined } from "@mui/icons-material";
import { PRDetail, PRLast } from "../types/pr";

export type DraftDetailResultProps = {
  detail: PRDetail | undefined;
};

const DraftDetailResult: FC<DraftDetailResultProps> = (props) => {
  const data = props.detail?.data.first;

  useEffect(() => {
    return () => {};
  }, []);


  // セクション名をtable_of_contentsから取得
  const sections = Object.keys(data?.table_of_contents || {});

  return (
    <Box
      flexGrow={1}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      rowGap={2}
      padding={2}
    >
      <Typography variant="h5">PR情報</Typography>
      <Typography variant="h6" sx={{ marginTop: 4 }}>
        本文情報
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={"170px"}>
              <Typography variant="body2" whiteSpace={"nowrap"}>
                タイトル
              </Typography>
            </TableCell>
            <TableCell>
              <Typography whiteSpace={"pre-wrap"}>{data?.title}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={"170px"}>
              <Typography variant="body2" whiteSpace={"nowrap"}>
                概要
              </Typography>
            </TableCell>
            <TableCell>
              <Typography whiteSpace={"pre-wrap"}>{data?.summary}</Typography>
            </TableCell>
          </TableRow>
          {/* セクション情報をtable_of_contentsから動的に表示 */}
          {sections.map((sectionName, index) => (
            <TableRow key={index}>
              <TableCell width={"170px"}>
                <Typography variant="body2" whiteSpace={"nowrap"}>
                  {
                    data?.table_of_contents[
                      sectionName as keyof typeof data.table_of_contents
                    ]
                  }
                </Typography>
              </TableCell>
              <TableCell>
                <Typography whiteSpace={"pre-wrap"}>
                  {data?.details[sectionName as keyof typeof data.details]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default DraftDetailResult;
