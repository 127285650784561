export const PATHS = {
  // ユーザー
  AUTH_LOGIN: "/",
  AUTH_PASSWORD_RESET: "/password-reset",
  AUTH_PASSWORD_FORGET: "/password-forget",
  AUTH_ACCOUNT: "/account",
  DRAFT: "/draft",
  SETTING_USER: "/setting",
  // 管理者
  PRONPT: "/prompt",
  ACCOUNT: "/company",
} as const;
